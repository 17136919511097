<template>
  <b-card>
    <!-- form -->
    <AppForm
      plain
      footer-classes="d-flex mt-2  justify-content-end mb-0"
      :errors-bag="errorsBag"
      overlay-opacity="1"
      :is-loading="isLoading"
      :submit-label="$t('save') || 'Save'"
      :remove-cancel-btn="true"
      :success-message="successMessage"
      @submit.prevent="submitHandler"
    >
      <b-media no-body class="mb-2 d-flex align-items-end">
        <b-media-aside>
          <b-img
            v-if="form && form.image && typeof form.image === 'string'"
            ref="previewEl"
            rounded
            :src="form.image"
            height="80"
            contain
            class="object-fit-contain"
            width="100"
          />
          <b-avatar
            v-else
            :src="null"
            :variant="`light-primary`"
            size="80px"
            rounded
          />
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <AppButton
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            {{ $t("upload") || "Upload" }}
          </AppButton>
          <b-form-file
            ref="refInputEl"
            v-model="file"
            accept=".jpg, .png, .jpeg"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
        </b-media-body>
      </b-media>
      <b-row>
        <b-col sm="6">
          <AppInput
            id="name-input"
            v-model="form.name"
            :label="$t('name') || 'Name'"
            name="name"
            type="text"
            required
            :placeholder="$t('name') || 'Name'"
          />
        </b-col>
        <b-col sm="6">
          <AppInput
            id="mobile-input"
            v-model="form.mobile"
            :label="$t('mobile') || 'Mobile'"
            name="mobile"
            required
            autocomplete="phone"
            :placeholder="$t('mobile') || 'Mobile'"
          />
        </b-col>
        <b-col sm="6">
          <AppInput
            id="email-input"
            v-model="form.email"
            :label="$t('email') || 'Email'"
            name="email"
            type="email"
            rule="email"
            disabled
            :placeholder="$t('email') || 'Email'"
          />
        </b-col>
      </b-row>
    </AppForm>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import { AppForm, AppButton, AppInput } from "@/components/form/index";

export default {
  components: {
    AppForm,
    AppButton,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    AppInput,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        image: "",
        mobile: "",
      },
      file: null,
      successMessage: null,
    };
  },
  computed: {
    userData() {
      return (
        this.$store.state.auth.userData ||
        this.$store.state.users.userData ||
        JSON.parse(window.localStorage.getItem("userData"))
      );
    },
    errorsBag() {
      return this.$store.state.users.errors;
    },
    isLoading() {
      return (
        this.$store.state.users.isLoading.fetching ||
        this.$store.state.users.isLoading.creating ||
        this.$store.state.users.isLoading.updating
      );
    },
  },
  created() {
    this.$store.dispatch("users/fetchUserSettings").then((res) => {
      this.populateData();
    });
  },
  methods: {
    populateData() {
      this.form = JSON.parse(JSON.stringify(this.userData));
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    submitHandler(e) {
      // console.log("e", e);
      const formData = new FormData();
      this.form.name && formData.append("name", this.form.name);
      this.file && formData.append("image", this.file);
      this.form.mobile && formData.append("mobile", this.form.mobile);

      this.$store.dispatch("users/updateUserSettings", formData).then((res) => {
        // this.$store.dispatch("users/fetchUserSettings").then((res) => {
        //   this.form = this.userData;
        // });
        this.$store.dispatch("auth/setUserData", res.data);

        const userData = JSON.parse(window.localStorage.getItem("userData"));

        userData.image = res.data.image;

        window.localStorage.setItem("userData", JSON.stringify(userData));

        this.successMessage =
          this.$t("profile_has_been_updated_successfully") ||
          "Profile has been updated Successfully";
      });
    },
    inputImageRenderer(file) {
      // console.log("file", file);
      if (!file) return [];
      if (typeof file === "string") return [file];
      this.form.image = URL.createObjectURL(file);
    },
  },
  // setup() {
  //   const refInputEl = ref(null);
  //   const previewEl = ref(null);

  //   const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

  //   return {
  //     refInputEl,
  //     previewEl,
  //     inputImageRenderer,
  //   };
  // },
};
</script>
