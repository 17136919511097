<template>
  <b-card>
    <!-- form -->
    <AppForm
      plain
      footer-classes="d-flex justify-content-end mb-0"
      :errors-bag="errorsBag"
      overlay-opacity="1"
      :is-loading="isLoading"
      :submit-label="$t('save_changes') || 'Save Changes'"
      :remove-cancel-btn="true"
      :success-title="$t('success') || 'Success'"
      :success-message="successMessage"
      @submit.prevent="submitHandler"
    >
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            :label="$t('old_password') || 'Old Password'"
            label-for="account-old-password"
            class="required"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="form.current_password"
                name="old-password"
                required
                :type="passwordFieldTypeOld"
                :placeholder="$t('old_password') || 'Old Password'"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            :label="$t('new_password') || 'New Password'"
            class="required"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="form.new_password"
                :type="passwordFieldTypeNew"
                required
                name="new-password"
                :placeholder="$t('new_password') || 'New Password'"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            class="required"
            :label="$t('retype_new_password') || 'Retype New Password'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="form.new_confirm_password"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                required
                :placeholder="$t('new_password') || 'New Password'"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->
      </b-row>
    </AppForm>
  </b-card>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { AppForm } from "@/components/form/index";

export default {
  components: {
    AppForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      form: {
        current_password: "",
        new_password: "",
        new_confirm_password: "",
      },
      successMessage: null,
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    errorsBag() {
      return this.$store.state.users.errors;
    },
    isLoading() {
      return (
        this.$store.state.users.isLoading.fetching ||
        this.$store.state.users.isLoading.creating ||
        this.$store.state.users.isLoading.updating
      );
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    submitHandler(e) {
      this.$store.dispatch("users/updateUserPassword", this.form).then(() => {
        // this.$store.dispatch("users/fetchUserSettings").then((res) => {
        //   this.form = this.userData;
        // });
        this.successMessage =
          this.$t("password_has_been_updated_successfully") ||
          "Password has been updated Successfully";
      });
    },
  },
};
</script>
